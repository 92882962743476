<template>
  <div class="page-rule">
    <div class="rule-tit">
      排号说明
    </div>
    <div class="ruel_content">
      {{ ruledata.introduction }}
    </div>
  </div>
</template>

<script>
import { getRule } from '@/api/data'
import { mapGetters } from 'vuex'
import { Button, Empty, Col, Row, Dialog } from 'vant'

export default {
  name: 'HomeIndex',
  components: {
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Col.name]: Col,
    [Row.name]: Row,
    [Dialog.name]: Dialog
  },
  data() {
    return {
      logo: require('../../assets/logo.png'),
      ruledata: '',
      listQuery: {
        sysset_id: '1'
      }
    }
  },
  computed: {
    avatar() {
      return this.userInfo ? this.userInfo.avatar : this.logo
    },
    introduction() {
      return this.userInfo ? this.userInfo.introduction : '南京蓝牛软件有限公司'
    },
    ...mapGetters([
      'userInfo'
    ])
  },
  created() {
    this.getRule()
  },
  methods: {
    async getRule() {
      this.$toast.loading({ message: '加载中' })
      const { data } = await getRule(this.listQuery)
      this.ruledata = data
      console.log(this.ruledata)
      this.$toast.clear()
    }
  }
}
</script>

<style lang="less" scoped>
  .page-rule{width: 100%;float: left;}
  .rule-tit{
    width: 90%;margin: 15px 5%;
    float: left;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #101112;
  }
  .ruel_content{
    width: 90%;
    margin:5px 5%;
    float: left;
    font-size: 15px;
    line-height: 26px;
    color: #101112;
  }
  .ruel_content p{margin: 5px 0;text-indent: 20px;}
</style>
